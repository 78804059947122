/*首页通用布局*/
<template>
  <div class="">
    <Header :hot="hot" />
    <div>
      <router-view />
    </div>
    <Footer :hot="hot" />
    <Service :hot="hot" />
<!--    <Message :hot="hot" />-->
  </div>
</template>
<script>
import Header from './Header'
import Footer from './Footer'
import Service from './Service'
import Message from "@/components/Message"
import {contactInfo} from "@/api";
export default {
  components: {
    Message,
    Service,
    Header,
    Footer
  },
  data () {
    return {
      hot: {}
    }
  },
  created() {
    console.log(this.$route.meta)
    this.getContactInfo()
  },
  methods: {
    async getContactInfo() {
      const {data} = await contactInfo()
      if (data.code === 0) {
        this.hot = data.data
      }
    },
  }
}
</script>

