import Vue from 'vue'
import 'vant/lib/index.css'

// 按名称顺序排序
import {
  Area,
  Button,
  Cascader,
  Calendar,
  Col,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  DatetimePicker,
  Dialog,
  Empty,
  Field,
  Form,
  Icon,
  Image,
  ImagePreview,
  Lazyload,
  List,
  NumberKeyboard,
  PasswordInput,
  Picker,
  Popup,
  Popover,
  Row,
  RadioGroup,
  Radio,
  Search,
  Tabs,
  Tab,
  Uploader,
  Swipe,
  SwipeItem,
  Switch,
  Sticky,
  ShareSheet,
  Toast,
} from 'vant'

Vue.use(Area)
Vue.use(Button)
Vue.use(Cascader)
Vue.use(Calendar)
Vue.use(Checkbox).use(CheckboxGroup)
Vue.use(Collapse).use(CollapseItem)
Vue.use(Cell).use(CellGroup)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(DatetimePicker)
Vue.use(Field)
Vue.use(Form)
Vue.use(Icon)
Vue.use(Image)
Vue.use(ImagePreview)
Vue.use(Lazyload)
Vue.use(List)
Vue.use(NumberKeyboard)
Vue.use(PasswordInput)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Popover)
Vue.use(Picker)
Vue.use(Row).use(Col)
Vue.use(RadioGroup).use(Radio)
Vue.use(Search)
Vue.use(Tabs).use(Tab)
Vue.use(Uploader)
Vue.use(Swipe).use(SwipeItem)
Vue.use(Row)
Vue.use(Switch)
Vue.use(Sticky)
Vue.use(ShareSheet)
Vue.use(Toast)
