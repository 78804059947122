import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.scss'
import './utils/vant' // 有赞ui库
import router from "./router"
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueClipboard)

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
