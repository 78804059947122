<template>
  <div class="footer pt38 pb7">
    <div class="container">
      <div class="top pb25">
        <div class="logo mt15 mr140">
          <img src="@/assets/images/common/logo1.png" alt="">
        </div>
        <div class="mr140">
          <div class="footerTitle fs16 c-ff pb12">快速链接</div>
          <div class="lis">
            <router-link to="/course" class="c-ff fs14">线上名师课程</router-link>
            <router-link to="/course" class="c-ff fs14">线上名师课程</router-link>
          </div>
        </div>
        <div class="mr140">
          <div class="footerTitle fs16 c-ff pb12" style="width: 160px;">联系方式</div>
          <div class="lis">
            <div class="c-ff fs14"> 联系人：{{ hot.contactPerson }}</div>
          </div>
          <div class="lis">
            <div class="c-ff fs14"> 联系电话：{{ hot.phone }}</div>
          </div>
          <div class="lis">
            <div class="c-ff fs14">邮箱：{{hot.email}}</div>
          </div>
          <div class="lis">
            <div class="c-ff fs14" style="white-space: nowrap;">联系地址：{{ hot.address }}</div>
          </div>
        </div>
        <div class="">
          <div class="footerTitle fs16 c-ff pb12">二维码</div>
          <div class="pt13">
            <div>
              <img :src="hot.qrcodeUrl" alt="" width="92px" height="92px" />
            </div>
            <div class="textCenter c-ff pt10">关注我们</div>
          </div>
        </div>
      </div>
      <div class="bottom fs12 textCenter pt8 pb8">
        Copyright © 2019 上馨心理咨询服务有限公司 All rights reserved
        <a href="http://beian.miit.gov.cn/" target="_blank" class="ml10" style="color:#d0cfcf;">粤ICP备2022135707号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hot: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">
  .bottom {
    border-top: 1px solid #444;
    color: #d0cfcf;
  }
  .mr140 {
    margin-right: 90px;
  }
  .footer {
    background: #665b5b;
  }
  .top {
    display: flex;
    .footerTitle {
      border-bottom: 1px solid #fff;
    }
    .lis {
      padding-top: 13px;
      display: flex;
      justify-content: space-between;
      min-width: 240px;
    }
  }
</style>
