<template>
  <div class="service">
    <div class="serviceWrap">
      <div class="serviceQrcode">
        <img :src="hot.qrcodeUrl" alt="" class="qrcodeS">
        <div class="fs12 c-ff textCenter pt5">在线客服</div>
      </div>
      <div class="pt15 textCenter">
        <img :src="require('@/assets/images/common/serviceIcon.png')" alt="" class="vMiddle" width="15px">
        <span class="c-ff fs14 ml7 inlineBlock vMiddle">客户服务热线</span>
      </div>
      <div class="fs16 c-ff pt8 textCenter">{{ hot.hotPhone }}</div>
    </div>
    <el-backtop target=".app">
      <div class="toTop">
        TOP
      </div>
    </el-backtop>
  </div>
</template>

<script>
 export default {
   props: {
     hot: {
       type: Object,
       default: () => ({})
     }
   }
 }
</script>

<style lang="scss" scoped>
  .service {
    position: fixed;
    right: 20px;
    width: 160px;
    z-index: 9998;
    top: 200px;
  }
  .serviceWrap {
    padding: 10px 0;
    background: #25a8f1;
  }
  .serviceQrcode {
    border-bottom: 1px solid #249de0;
    padding-bottom: 10px;
  }
  .qrcodeS {
    display: block;
    width: 100%;
    height: 160px;
  }
  .toTop {
    display: block;
    width: 112px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    background: #f39800;
    margin: 0 auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
  }
  /deep/ .el-backtop {
    position: relative;
    height: auto;
    width: auto;
    left: 0;
    top: 0;
    background: none;
    box-shadow: none;
    display: block;
  }
</style>
