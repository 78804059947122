import IndexLayout from "@/components/IndexLayout";
import WapIndexLayout from "@/components/WapIndexLayout";
import { checkDevice } from '@/utils'
//
// const flag = checkDevice()
// export default !flag ? [
//   {
//     path: "/",
//     component: IndexLayout,
//     children: [
//       {
//         path: '',
//         name: 'index',
//         component: () => import(/* webpackChunkName: "index" */ "@/views/index"),
//         meta: { type: 0, title: '上馨心理' }
//       },
//       {
//         path: '/news',
//         name: 'news',
//         component: () => import(/* webpackChunkName: "newsList" */ "@/views/news"),
//         meta: { type: 1, title: '新闻列表' }
//       },
//       {
//         path: '/news/:id',
//         name: 'newsDetail',
//         component: () => import(/* webpackChunkName: "newsDetail" */ "@/views/news/detail"),
//         meta: { type: 1, title: '新闻详情' }
//       },
//       {
//         path: '/welfare',
//         name: 'welfare',
//         component: () => import(/* webpackChunkName: "welfareList" */ "@/views/welfare"),
//         meta: { title: '上馨公益' }
//       },
//       {
//         path: '/welfare/:id',
//         name: 'welfareDetail',
//         component: () => import(/* webpackChunkName: "welfareDetail" */ "@/views/welfare/detail"),
//         meta: { title: '上馨公益详情' }
//       },
//       {
//         path: '/research',
//         name: 'research',
//         component: () => import(/* webpackChunkName: "researchList" */ "@/views/research"),
//         meta: { title: '研究中心' }
//       },
//       {
//         path: '/research/:id',
//         name: 'researchDetail',
//         component: () => import(/* webpackChunkName: "researchDetail" */ "@/views/research/detail"),
//         meta: { title: '研究中心详情' }
//       },
//       {
//         path: '/course',
//         name: 'course',
//         component: () => import(/* webpackChunkName: "course" */ "@/views/course"),
//         meta: { title: '课程列表' }
//       },
//       {
//         path: '/course/:id',
//         name: 'courseDetail',
//         component: () => import(/* webpackChunkName: "courseDetail" */ "@/views/course/detail"),
//         meta: { title: '课程详情' }
//       },
//       {
//         path: '/consultation',
//         name: 'consultation',
//         component: () => import(/* webpackChunkName: "consultation" */ "@/views/consultation"),
//         meta: { title: '心理咨询' }
//       },
//       {
//         path: '/consultation/:id',
//         name: 'consultationDetail',
//         component: () => import(/* webpackChunkName: "consultationDetail" */ "@/views/consultation/detail"),
//         meta: { title: '心理咨询详情' }
//       },
//       {
//         path: '/cases',
//         name: 'cases',
//         component: () => import(/* webpackChunkName: "cases" */ "@/views/cases"),
//         meta: { title: '企业EAP案例' }
//       },
//       {
//         path: '/cases/:id',
//         name: 'casesDetail',
//         component: () => import(/* webpackChunkName: "casesDetail" */ "@/views/cases/detail"),
//         meta: { title: '企业EAP案例详情' }
//       },
//       {
//         path: '/honor',
//         name: 'honor',
//         component: () => import(/* webpackChunkName: "honor" */ "@/views/honor"),
//         meta: { title: '企业荣誉' }
//       },
//       {
//         path: '/honor/:id',
//         name: 'honorDetail',
//         component: () => import(/* webpackChunkName: "honorDetail" */ "@/views/honor/detail"),
//         meta: { title: '企业荣誉详情' }
//       },
//       {
//         path: '/about',
//         name: 'aboutDetail',
//         component: () => import(/* webpackChunkName: "aboutDetail" */ "@/views/about"),
//         meta: { type: 1, title: '关于我们' }
//       },
//       {
//         path: '/feedback',
//         name: 'feedback',
//         component: () => import(/* webpackChunkName: "feedback" */ "@/views/feedback"),
//         meta: { title: '客户好评反馈' }
//       },
//       {
//         path: '/feedback/:id',
//         name: 'feedbackDetail',
//         component: () => import(/* webpackChunkName: "feedbackDetail" */ "@/views/feedback/detail"),
//         meta: { title: '客户好评反馈详情' }
//       },
//       {
//         path: '/introduction',
//         name: 'introduction',
//         component: () => import(/* webpackChunkName: "introduction" */ "@/views/introduction"),
//         meta: { title: '公司简介' }
//       },
//       {
//         path: '/concat',
//         name: 'concat',
//         component: () => import(/* webpackChunkName: "concat" */ "@/views/concat"),
//         meta: { title: '联系我们' }
//       },
//       {
//         path: '/company',
//         name: 'company',
//         component: () => import(/* webpackChunkName: "company" */ "@/views/company"),
//         meta: { title: '分公司列表' }
//       }
//     ]
//   }
// ] : [
//   {
//     path: "/",
//     component: WapIndexLayout,
//     children: [
//       {
//         path: '',
//         name: 'index',
//         component: () => import(/* webpackChunkName: "wapIndex" */ "@/views/wap/index"),
//         meta: { type: 0, title: '上馨心理' }
//       },
//       {
//         path: '/news',
//         name: 'news',
//         component: () => import(/* webpackChunkName: "wapNewsList" */ "@/views/wap/news"),
//         meta: { type: 1, title: '新闻列表' }
//       },
//       {
//         path: '/news/:id',
//         name: 'newsDetail',
//         component: () => import(/* webpackChunkName: "wapNewsDetail" */ "@/views/wap/news/detail"),
//         meta: { type: 1, title: '新闻详情' }
//       },
//       {
//         path: '/course',
//         name: 'course',
//         component: () => import(/* webpackChunkName: "wapCourse" */ "@/views/wap/course"),
//         meta: { title: '课程列表' }
//       },
//       {
//         path: '/course/:id',
//         name: 'courseDetail',
//         component: () => import(/* webpackChunkName: "wapCourseDetail" */ "@/views/wap/course/detail"),
//         meta: { title: '课程详情' }
//       },
//       {
//         path: '/consultation',
//         name: 'consultation',
//         component: () => import(/* webpackChunkName: "wapConsultation" */ "@/views/wap/consultation"),
//         meta: { title: '心理咨询' }
//       },
//       {
//         path: '/consultation/:id',
//         name: 'consultationDetail',
//         component: () => import(/* webpackChunkName: "wapConsultationDetail" */ "@/views/wap/consultation/detail"),
//         meta: { title: '心理咨询详情' }
//       },
//       {
//         path: '/cases',
//         name: 'cases',
//         component: () => import(/* webpackChunkName: "wapCases" */ "@/views/wap/cases"),
//         meta: { title: '企业EAP案例' }
//       },
//       {
//         path: '/cases/:id',
//         name: 'casesDetail',
//         component: () => import(/* webpackChunkName: "wapCasesDetail" */ "@/views/wap/cases/detail"),
//         meta: { title: '企业EAP案例详情' }
//       },
//       {
//         path: '/honor',
//         name: 'honor',
//         component: () => import(/* webpackChunkName: "wapHonor" */ "@/views/wap/honor"),
//         meta: { title: '企业荣誉' }
//       },
//       {
//         path: '/honor/:id',
//         name: 'honorDetail',
//         component: () => import(/* webpackChunkName: "wapHonorDetail" */ "@/views/wap/honor/detail"),
//         meta: { title: '企业荣誉详情' }
//       },
//       {
//         path: '/about',
//         name: 'aboutDetail',
//         component: () => import(/* webpackChunkName: "wapAboutDetail" */ "@/views/wap/about"),
//         meta: { type: 1, title: '关于我们' }
//       },
//       {
//         path: '/feedback',
//         name: 'feedback',
//         component: () => import(/* webpackChunkName: "wapFeedback" */ "@/views/wap/feedback"),
//         meta: { title: '客户好评反馈' }
//       },
//       {
//         path: '/feedback/:id',
//         name: 'feedbackDetail',
//         component: () => import(/* webpackChunkName: "wapFeedbackDetail" */ "@/views/wap/feedback/detail"),
//         meta: { title: '客户好评反馈详情' }
//       },
//       {
//         path: '/introduction',
//         name: 'introduction',
//         component: () => import(/* webpackChunkName: "wapIntroduction" */ "@/views/wap/introduction"),
//         meta: { title: '公司简介' }
//       },
//       {
//         path: '/concat',
//         name: 'concat',
//         component: () => import(/* webpackChunkName: "wapConcat" */ "@/views/wap/concat"),
//         meta: { title: '联系我们' }
//       },
//       {
//         path: '/company',
//         name: 'company',
//         component: () => import(/* webpackChunkName: "wapCompany" */ "@/views/wap/company"),
//         meta: { title: '分公司列表' }
//       }
//     ]
//   }
// ];
export default [
  {
    path: "/",
    component: IndexLayout,
    children: [
      {
        path: '',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ "@/views/index"),
        meta: { type: 0, title: '上馨心理' }
      },
      {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "newsList" */ "@/views/news"),
        meta: { type: 1, title: '新闻列表' }
      },
      {
        path: '/news/:id',
        name: 'newsDetail',
        component: () => import(/* webpackChunkName: "newsDetail" */ "@/views/news/detail"),
        meta: { type: 1, title: '新闻详情' }
      },
      {
        path: '/welfare',
        name: 'welfare',
        component: () => import(/* webpackChunkName: "welfareList" */ "@/views/welfare"),
        meta: { title: '上馨公益' }
      },
      {
        path: '/welfare/:id',
        name: 'welfareDetail',
        component: () => import(/* webpackChunkName: "welfareDetail" */ "@/views/welfare/detail"),
        meta: { title: '上馨公益详情' }
      },
      {
        path: '/research',
        name: 'research',
        component: () => import(/* webpackChunkName: "researchList" */ "@/views/research"),
        meta: { title: '研究中心' }
      },
      {
        path: '/research/:id',
        name: 'researchDetail',
        component: () => import(/* webpackChunkName: "researchDetail" */ "@/views/research/detail"),
        meta: { title: '研究中心详情' }
      },
      {
        path: '/course',
        name: 'course',
        component: () => import(/* webpackChunkName: "course" */ "@/views/course"),
        meta: { title: '课程列表' }
      },
      {
        path: '/course/:id',
        name: 'courseDetail',
        component: () => import(/* webpackChunkName: "courseDetail" */ "@/views/course/detail"),
        meta: { title: '课程详情' }
      },
      {
        path: '/consultation',
        name: 'consultation',
        component: () => import(/* webpackChunkName: "consultation" */ "@/views/consultation"),
        meta: { title: '心理咨询' }
      },
      {
        path: '/consultation/:id',
        name: 'consultationDetail',
        component: () => import(/* webpackChunkName: "consultationDetail" */ "@/views/consultation/detail"),
        meta: { title: '心理咨询详情' }
      },
      {
        path: '/cases',
        name: 'cases',
        component: () => import(/* webpackChunkName: "cases" */ "@/views/cases"),
        meta: { title: '企业EAP案例' }
      },
      {
        path: '/cases/:id',
        name: 'casesDetail',
        component: () => import(/* webpackChunkName: "casesDetail" */ "@/views/cases/detail"),
        meta: { title: '企业EAP案例详情' }
      },
      {
        path: '/eap/:id',
        name: 'eapDetail',
        component: () => import(/* webpackChunkName: "eapDetail" */ "@/views/cases/eap"),
        meta: { title: '企业EAP案例详情' }
      },
      {
        path: '/honor',
        name: 'honor',
        component: () => import(/* webpackChunkName: "honor" */ "@/views/honor"),
        meta: { title: '企业荣誉' }
      },
      {
        path: '/honor/:id',
        name: 'honorDetail',
        component: () => import(/* webpackChunkName: "honorDetail" */ "@/views/honor/detail"),
        meta: { title: '企业荣誉详情' }
      },
      {
        path: '/about',
        name: 'aboutDetail',
        component: () => import(/* webpackChunkName: "aboutDetail" */ "@/views/about"),
        meta: { type: 1, title: '关于我们' }
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: () => import(/* webpackChunkName: "feedback" */ "@/views/feedback"),
        meta: { title: '客户好评反馈' }
      },
      {
        path: '/feedback/:id',
        name: 'feedbackDetail',
        component: () => import(/* webpackChunkName: "feedbackDetail" */ "@/views/feedback/detail"),
        meta: { title: '客户好评反馈详情' }
      },
      {
        path: '/introduction',
        name: 'introduction',
        component: () => import(/* webpackChunkName: "introduction" */ "@/views/introduction"),
        meta: { title: '公司简介' }
      },
      {
        path: '/concat',
        name: 'concat',
        component: () => import(/* webpackChunkName: "concat" */ "@/views/concat"),
        meta: { title: '联系我们' }
      },
      {
        path: '/company',
        name: 'company',
        component: () => import(/* webpackChunkName: "company" */ "@/views/company"),
        meta: { title: '分公司列表' }
      }
    ]
  }
]
