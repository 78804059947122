/**
 * Created by hlp on 2019/06/18
 */
import axios from "axios"
import { Message } from "element-ui"

// axios请求配置
const service = axios.create({
  baseURL: "",
  custom: {}
  //timeout: 10000
})

service.defaults.withCredentials = true;
service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'  //设置请求头的类型

service.interceptors.request.use(configs => {

  // 如果是get请求，带上时间戳
  if (configs.method === "get") {
    // console.log(configs.data, 'dddd')
    configs.params = {
      _t: Date.parse(new Date()) / 1000,
      ...configs.data
    };
  }

  configs.url = 'https://www-api.shangxinxl.com' + configs.url
  return configs
}, error => {
  Promise.reject(error)
});

service.interceptors.response.use(
  res => {
    return res
  },
  error => {
    // 如果如果请求不是cancel调的才提示
    const errorInfo = error.toString().toLowerCase()
    if (!errorInfo.includes('cancel') && !errorInfo.includes('aborted')) {
      Message.error({
        message: "网络请求失败，请稍后重试！",
        offset: 50
      })
    }
    return Promise.reject(error)
  }
)

export default service
