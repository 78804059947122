<template>
  <div class="message bg-ff">
    <div class="msgTitle fs14 c-ff pl12 pr5">
      <span>留言板</span>
      <img v-if="show" :src="require('@/assets/images/common/nodeMax.png')" alt="" class="nodeMax" @click="show = false">
      <img v-else :src="require('@/assets/images/common/nodeMin.png')" alt="" class="nodeMin" @click="show = true">
    </div>
    <div v-show="show" class="pl10 pr10">
      <div class="fs12 c-333 pt10 pb10">
        广州上馨心理咨询服务有限公司{{ hot.hotPhone }}
      </div>
      <el-form ref="form" :model="form">
        <el-form-item prop="messageContent">
          <el-input v-model="form.messageContent" class="fs12" resize="none" rows="4" type="textarea" placeholder="请在此输入留言内容，我们会尽快与您联系。（必填）"></el-input>
        </el-form-item>
        <el-form-item class="iconsWrap" prop="realName">
          <span class="el-icon-user icons"></span>
          <el-input v-model="form.realName" class="fs12"  placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item class="iconsWrap" prop="phone">
          <span class="el-icon-phone-outline icons"></span>
          <el-input v-model="form.phone" class="fs12"  placeholder="电话（必填）"></el-input>
        </el-form-item>
        <el-form-item class="iconsWrap" prop="wechart">
          <span class="el-icon-chat-round icons"></span>
          <el-input v-model="form.wechart" class="fs12"  placeholder="微信"></el-input>
        </el-form-item>
        <div class="textRight pb10">
          <span class="inlineBlock submitBtn" @click="submitFn">提交</span>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
  import {messageInfo} from "@/api";

  export default {
    props: {
      hot: {
        type: Object,
        default: () => ({})
      }
    },
    data () {
      return {
        show: true,
        form: {},
        // rules: {
        //   messageContent:{ required: true, message: '请输入留言内容'},
        //   phone:{ required: true, message: '请输入电话'},
        // }
      }
    },
    methods: {
      async submitFn () {
        if (!this.form.messageContent) return this.$message.error('请输入留言内容')
        const reg = /^1\d{10}$/
        if (!this.form.phone || !reg.test(this.form.phone)) return this.$message.error('请输入正确的电话')
        const { data } = await messageInfo(this.form)
        if (data.code === 0) {
          this.$message.success('留言成功，请耐心等待客服人员联系')
          this.form = {}
        }
        // this.$refs.form.validate((valid) => {
        //   if (valid) {
        //     console.log(this.form)
        //   }
        // })
      }
    }
  }
</script>
<style scoped lang="scss">
  .submitBtn {
    height: 32px;
    width: 64px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    background-color: #6696FF;
  }
  .iconsWrap {
    position: relative;
    top: 0;
    .icons {
      position: absolute;
      top: 14px;
      left: 10px;
      z-index: 99;
      color: #999;
    }
    /deep/ .el-input__inner {
      padding-left: 35px;
    }
  }
  .message {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 9999;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.12);
    border-radius: 8px;
    width: 240px;
    overflow: hidden;
  }
  .msgTitle {
    background: #6696FF;
    height: 45px;
    line-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nodeMax {
    width: 37px;
    cursor: pointer;
  }
  .nodeMin {
    margin-right: 10px;
    width: 15px;
    cursor: pointer;
  }
  /deep/ .el-form-item {
    margin-bottom: 9px;
  }
</style>
