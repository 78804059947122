// import qs from "qs"
import request from "@/utils/request"

// 企业荣誉文档
export const enterpriseHonor = (data) => {
	return request.get("/enterpriseHonor/list", data)
}

// 轮播图
export const carouselList = (data) => {
	return request.get("/carousel/list", { data: data })
}

// 新闻动态分类
export const xinArticleCategory = () => {
  return request.get("/xinArticle/categoryList")
}

// 新闻动态列表
export const xinArticleList = (data) => {
	return request.get("/xinArticle/list", { data: data })
}

// 新闻动态详情
export const xinArticleInfo = (id) => {
	return request.get(`/xinArticle/info/${id}`)
}

// 关于上馨
export const aboutUs = () => {
	return request.get(`/home/aboutSX`)
}


// 客户好评
export const priseFeedbackList = (data) => {
	return request.get("/priseFeedback/list", { data: data })
}

// 客户好评详情
export const priseFeedbackInfo = (id) => {
	return request.get(`/priseFeedback/info/${id}`)
}

// 公司简介
export const companyShortInfo = () => {
	return request.get(`/home/companyShortInfo`)
}

// 联系我们
export const contactUs = () => {
	return request.get(`/home/contactUs`)
}

// 企业荣誉列表
export const honorList = (data) => {
	return request.get("/enterpriseHonor/list", { data: data })
}

// 企业荣誉详情
export const honorDetail = (id) => {
	return request.get(`/enterpriseHonor/info/${id}`)
}

// erp分类列表
export const erpCategoryList = () => {
	return request.get(`/eap/categoryList`)
}

// erp列表
export const erpList = (data) => {
	return request.get(`/eap/list`, { data: data })
}

// erp详情
export const erpInfo = (id) => {
	return request.get(`/eap/info/${id}`)
}

// eap详情
export const eapInfo = (id) => {
  return request.get(`/home/eapCopyInfo/${id}`)
}

// 分公司列表
export const branchCompanyList = (data) => {
	return request.get(`/branchCompany/list`, { data: data })
}


// 联系方式
export const contactInfo = () => {
	return request.get(`/home/contactInfo`)
}

// 留言板
export const messageInfo = (data) => {
	return request.post(`/home/save`, data, {
		headers: {
			'Content-Type': 'application/json;charset=utf-8'
		}
	})
}

// 心理课程列表
export const courseList = (data) => {
	return request.get(`/psychologyCourse/list`, { data: data })
}

// 首页心理课程
export const courseListHome = () => {
	return request.get(`/home/psychologyCourseList`)
}

// 心理课程分类
export const courseCategory = () => {
  return request.get(`/psychologyCourse/categoryList`)
}

// 课程详情
export const courseInfo = (id) => {
	return request.get(`/psychologyCourse/info/${id}`)
}


// 心理咨询分类列表
export const consulCategoryList = () => {
	return request.get(`/consul/categoryList`)
}

// 心理咨询列表
export const consulList = (data) => {
	return request.get(`/consul/list`, { data: data })
}

// 心理咨询详情
export const consulInfo = (id) => {
	return request.get(`/consul/info/${id}`)
}

// erp文案
export const eapCopyList = () => {
  return request.get('/home/eapCopyList')
}

// 公益分类列表
export const welfareCategoryList = () => {
	return request.get(`/publicBenefit/categoryList`)
}

// 公益列表
export const welfareList = (data) => {
	return request.get(`/publicBenefit/list`, { data: data })
}

// 公益详情
export const welfareDetail = (id) => {
	return request.get(`/publicBenefit/info/${id}`)
}

// 研究中心分类列表
export const researchCategoryList = () => {
	return request.get(`/researchCenter/categoryList`)
}

// 研究中心列表
export const researchList = (data) => {
	return request.get(`/researchCenter/list`, { data: data })
}

// 研究中心详情
export const researchDetail = (id) => {
	return request.get(`/researchCenter/info/${id}`)
}

// 首页研究中心
export const homeResearch = () => {
	return request.get(`/home/researchCenter`)
}

// 视频列表
export const getVideoList = (data) => {
	return request.get("/home/videoList", { data: data })
}
