import Vue from "vue"
import Router from "vue-router"
import home from './src/home'

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...home],
  // 路由跳转返回顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  next()
})

router.afterEach((to) =>{
  if(to.meta.title){
    let title = to.meta.title
    document.title = title
  }
})
export default router
