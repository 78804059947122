/*移动端布局*/
<template>
  <div>
    <div class="header">
      <div>
        <van-icon name="wap-nav" size="28" color="#333" @click="show = !show" />
      </div>
      <div class="logo">
        <img src="@/assets/images/common/logo.png" alt="" class="block" width="100%">
      </div>
    </div>
    <div>
      <router-view />
    </div>
    <div class="footer">
      <div class="footerFixed">
        <router-link to="/" class="footerItem">
          <img src="@/assets/images/wap/index-home.png" alt="" class="footerImg" />
          <div class="textCenter wfs24 wpt10 c-ff spans">
            首页
          </div>
        </router-link>
        <div class="footerItem" :class="{'curFooter': curFooter === 1}" @click="setCurFooter(1)">
          <img v-show="curFooter !== 1" src="@/assets/images/wap/index-phone.png" alt="" class="footerImg" />
          <img v-show="curFooter === 1" src="@/assets/images/wap/index-phone-1.png" alt="" class="footerImg" />
          <div class="textCenter wfs24 wpt10 c-ff spans">
            电话咨询
          </div>
        </div>
        <div class="footerItem" :class="{'curFooter': curFooter === 2}" @click="setCurFooter(2)">
          <img v-show="curFooter !== 2" src="@/assets/images/wap/index-wechat.png" alt="" class="footerImg" />
          <img v-show="curFooter === 2" src="@/assets/images/wap/index-wechat-1.png" alt="" class="footerImg" />
          <div class="textCenter wfs24 wpt10 c-ff spans">
            微信咨询
          </div>
        </div>
        <div class="footerItem" :class="{'curFooter': curFooter === 3}" @click="setCurFooter(3)">
          <img v-show="curFooter !== 3" src="@/assets/images/wap/index-email.png" alt="" class="footerImg" />
          <img v-show="curFooter === 3" src="@/assets/images/wap/index-email-1.png" alt="" class="footerImg" />
          <div class="textCenter wfs24 wpt10 c-ff spans">
            在线咨询
          </div>
        </div>
      </div>
    </div>
    <!--    右侧导航弹窗-->
    <van-popup
      v-model="show"
      closeable
      position="left"
      :style="{ height: '100vh', width: '80vw' }"
    >
      <div class="nav">
        <ul>
          <li class="borderBottom">
            <div class="wfs30 f500 c-333 downLis block" :class="{ 'curLink' : $route.name === 'index' }" @click="routerTo('/')">首页</div>
          </li>
          <li class="borderBottom">
            <div class="wfs30 f500 c-333 downLis downListIcon">公司简介</div>
            <ul class="innerList borderTop wpl60">
              <li class="wpt25 wpb25 borderBottom">
                <div class="innerListLinks wfs28 c-333" :class="{ 'curLink' : $route.name === 'introduction' }" @click="routerTo('/introduction')">公司简介</div>
              </li>
              <li class="wpt25 wpb25">
                <div class="innerListLinks wfs28 c-333" :class="{ 'curLink' : $route.name === 'honor' }" @click="routerTo('/honor')">企业荣誉</div>
              </li>
            </ul>
          </li>
          <li class="borderBottom">
            <div class="wfs30 f500 c-333 downLis downListIcon">企业EAP</div>
            <ul class="innerList borderTop wpl60">
              <li class="wpt25 wpb25 borderBottom">
                <div class="innerListLinks wfs28 c-333" :class="{ 'curLink' : $route.name === 'news' }" @click="routerTo('/news')">馨闻动态</div>
              </li>
              <li v-for="(item, index) in links" :key="item.id" class="wpt25 wpb25" :class="{'borderBottom': index !== links.length - 1}">
                <div class="innerListLinks wfs28 c-333" :class="{ 'curLink' : $route.query && $route.query.erp == item.id }" @click="routerTo(`/cases?erp=${item.id}`)">{{ item.categoryName }}</div>
              </li>
            </ul>
          </li>
          <li class="borderBottom">
            <div class="wfs30 f500 c-333 downLis downListIcon">心理课程</div>
            <ul class="innerList borderTop wpl60">
              <li v-for="(item, index) in links2" :key="item.id" class="wpt25 wpb25" :class="{'borderBottom': index !== links2.length - 1}">
                <div class="innerListLinks wfs28 c-333" :class="{ 'curLink' : $route.query && $route.query.cId == item.id }" @click="routerTo(`/course?cId=${item.id}`)">{{ item.categoryName }}</div>
              </li>
            </ul>
          </li>
          <li class="borderBottom">
            <div class="wfs30 f500 c-333 downLis downListIcon">心理咨询</div>
            <ul class="innerList borderTop wpl60">
              <li v-for="(item, index) in links1" :key="item.id" class="wpt25 wpb25" :class="{'borderBottom': index !== links1.length - 1}">
                <div class="innerListLinks wfs28 c-333" :class="{ 'curLink' : $route.query && $route.query.sul == item.id }" @click="routerTo(`/consultation?sul=${item.id}`)">{{ item.categoryName }}</div>
              </li>
            </ul>
          </li>
          <li class="borderBottom">
            <div class="wfs30 f500 c-333 downLis block" :class="{ 'curLink' : $route.name === 'concat' }" @click="routerTo('/concat')">联系我们</div>
          </li>
        </ul>
      </div>
    </van-popup>
    <!--    电话联系-->
    <van-popup v-model="showOne" round style="background: none;">
      <div class="phonePopup">
        <div class="phoneNumer">{{ hot.phone }}</div>
        <div class="phoneFlex">
          <div class="phoneFlexItem wpt25 wpb25" @click="copyPhone">
            <img src="@/assets/images/wap/index-icon.png" alt="" class="phoneImg" />
            <div class="wfs22 c-main textCenter wpt10">复制</div>
          </div>
          <a :href="`tel:${hot.phone}`" class="phoneFlexItem wpt25 wpb25">
            <img src="@/assets/images/wap/index-icon2.png" alt="" class="phoneImg" />
            <div class="wfs22 c-main textCenter wpt10">拨打</div>
          </a>
          <a :href="`tel:${hot.phone}`" class="phoneFlexItem wpt25 wpb25">
            <img src="@/assets/images/wap/index-icon3.png" alt="" class="phoneImg" />
            <div class="wfs22 c-main textCenter wpt10">通讯录</div>
          </a>
        </div>
      </div>
    </van-popup>
    <!--    二维码-->
    <van-popup v-model="showTwo" style="background: none;">
      <div class="qrcodeFixed">
        <img src="@/assets/images/common/qrcode.png" alt="" class="qrcodeBg" />
        <img :src="hot.qrcodeUrl" alt=""  class="qrcodeImg" />
      </div>
    </van-popup>
    <!--    留言板-->
    <van-popup v-model="showThree" round style="background: none;">
      <div class="message">
        <div class="msgTitle c-ff wpt20 wpb20 wpl45 wpr25 wfs28">
          留言板
        </div>
        <div class="msgForms bg-ff">
          <van-form @submit="onSubmit" label-width="20px">
            <van-field
                v-model="form.messageContent"
                name="messageContent"
                rows="3"
                autosize
                type="textarea"
                placeholder="请在此输入留言内容，我们会尽快与您联系(必填)"
                :rules="[{ required: true, message: '请填写留言内容' }]"
            />
            <van-field
              v-model="form.realName"
              name="realName"
              placeholder="姓名"
            >
              <div slot="label">
                <span class="el-icon-user icons"></span>
              </div>
            </van-field>
            <van-field
              v-model="form.phone"
              name="phone"
              placeholder="电话（必填）"
              :rules="[{ pattern: /^1\d{10}$/, message: '请填写电话' }]"
            >
              <div slot="label">
                <span class="el-icon-phone-outline icons"></span>
              </div>
            </van-field>
            <van-field
                v-model="form.wechart"
                name="wechart"
                placeholder="微信"
            >
              <div slot="label">
                <span class="el-icon-chat-round icons"></span>
              </div>
            </van-field>
            <div class="wpt45 wpb45 wpl35 wpr35">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {consulCategoryList, contactInfo, courseCategory, erpCategoryList, messageInfo} from "@/api";
  export default {
    data () {
      return {
        links2: [],
        links1: [],
        links: [],
        form: {},
        hot: {},
        curFooter: 0,
        show: false,
        showOne: false,
        showTwo: false,
        showThree: false,
      }
    },
    watch: {
      showOne(newVal) {
        if (!newVal && this.curFooter === 1) {
          this.curFooter = 0
        }
      },
      showTwo(newVal) {
        if (!newVal && this.curFooter === 2) {
          this.curFooter = 0
        }
      },
      showThree(newVal) {
        if (!newVal && this.curFooter === 3) {
          this.curFooter = 0
        }
      },
    },
    created() {
      this.getCategoryList()
      this.getCategoryList1()
      this.getCategoryList2()
      this.getContactInfo()
    },
    methods: {
      routerTo (toLink) {
        this.$router.push(toLink)
        this.show = false
      },
      async getCategoryList2() {
        const { data } = await courseCategory()
        if (data.code === 0) {
          this.links2 = data.data
        }
      },
      async getCategoryList1() {
        const { data } = await consulCategoryList()
        if (data.code === 0) {
          this.links1 = data.data
        }
      },
      async getCategoryList() {
        const { data } = await erpCategoryList()
        if (data.code === 0) {
          this.links = data.data
        }
      },
      async getContactInfo() {
        const {data} = await contactInfo()
        if (data.code === 0) {
          this.hot = data.data
        }
      },
      setCurFooter (index) {
        this.curFooter = this.curFooter === index ? 0 : index
        if (this.curFooter === 1) {
          this.showOne = true
        }
        if (this.curFooter === 2) {
          this.showTwo = true
        }
        if (this.curFooter === 3) {
          this.showThree = true
        }
      },
      copyPhone () {
        if (this.hot.phone) {
          this.$copyText(this.hot.phone).then( () => {
            this.$toast('复制成功')
          })
        }
      },
      async onSubmit(values) {
        const { data } = await messageInfo(values)
        if (data.code === 0) {
          this.$toast.success('留言成功，请耐心等待客服人员联系')
          this.form = {}
          this.showThree = false
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .phonePopup {
    width: 70vw;
  }
  .footer {
    height: 14.2vw;
    .footerFixed {
      box-sizing: border-box;
      height: 14.2vw;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $maincss;
      display: flex;
      align-items: center;
      .footerItem {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        &.curFooter {
          background: #fff;
          .spans {
            color: $maincss;
          }
        }
      }
      .footerImg {
        display: block;
        height: 4vw;
        margin: 0 auto;
      }
    }
  }
  .header {
    display: flex;
    padding: 2.13vw 2.67vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0;
    z-index: 9999;
    background: #fff;
    .logo {
      width: 11.73vw;
    }
  }
  .nav {
    padding-top: 18.33vw;
  }
  .downLis {
    padding: 3.73vw 3.2vw;
  }
  //.downListIcon {
  //  position: relative;
  //  top: 0;
  //  &:before {
  //    content: "";
  //    position: absolute;
  //    right: 3.2vw;
  //    top: 6.2vw;
  //    border: 2.5vw solid transparent;
  //    border-top-color: #999;
  //  }
  //  &:after {
  //    content: "";
  //    position: absolute;
  //    right: 3.2vw;
  //    top: 5.8vw;
  //    border: 2.5vw solid transparent;
  //    border-top-color: #fff;
  //  }
  //  &.rotate:before {
  //    top: 2.8vw;
  //    transform: rotate(180deg);
  //  }
  //  &.rotate:after {
  //    top: 3.2vw;
  //    transform: rotate(180deg);
  //  }
  //}
  .innerList {
    .innerListLinks {
      display: block;
    }
  }
  .phoneNumer {
    text-align: center;
    height: 25vw;
    background: #25a8f1;
    line-height: 25vw;
    color: #fff;
    font-size: 5vw;
  }
  .phoneFlex {
    background: #fff;
    box-shadow: 0px 0.3125vw 0.9375vw rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    .phoneFlexItem {
      flex: 1;
      .phoneImg {
        display: block;
        width: 11vw;
        margin: 0 auto;
      }
    }
  }
  .qrcodeFixed {
    width: 75vw;
    box-sizing: border-box;
    position: relative;
    top: 0;
    .qrcodeBg {
      width: 100%;
      display: block;
    }
    .qrcodeImg {
      display: block;
      width: 33vw;
      position: absolute;
      top: 28vw;
      left: 21vw;
    }
  }
  .message {
    width: 85vw;
    .msgTitle {
      background: #6696FF;
    }
  }
  .curLink {
    color: $maincss;
  }
</style>

